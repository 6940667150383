/*
    Created on : 10.12.2021, 06:18:59
    Author     : Andy Kar
*/

import {combineReducers} from 'redux'
import initReducer from "./initReducer";
import calculateParams from "./calculateRarams";
import feedbackReducer from "./feedbackReducer";

export default combineReducers({
    init: initReducer,
    params: calculateParams,
    feedback: feedbackReducer
})