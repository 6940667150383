/*
    Created on : 12.12.2021, 06:18:59
    Author     : Andy Kar
*/
import React from 'react'
import {useDispatch, useSelector} from "react-redux";
import {InitDataInterface, StoreInterface} from "../../interfaces/mainInterfaces";
import {SEND_FEEDBACK_INFO} from "../../store/actions/actionTypes";

function FormSendBlock() {
    const data:InitDataInterface = useSelector((state:StoreInterface) => state.init.data)
    const feedback = useSelector((state:StoreInterface) => state.feedback.feedback)

    const dispatch = useDispatch()
    const sendFeedback = () => {
        const formName:any  = 'publicCalcForm'
        const feedbackForm: HTMLFormElement | null = document.forms[formName]
        if(feedbackForm) {
            const postData = new FormData(feedbackForm)
            postData.append('formName', data.title + " маршрутных такси")
            const feedbackData = {
                postData: postData,
                url: data.mailApi
            }
            dispatch({type: SEND_FEEDBACK_INFO, feedbackData})
        }
    }

    return (
        <div className="row mt-56">
            <div className="col-lg-3 col-md-4 col-sm-6">
                <div className="form-floating">
                    <input required type="tel" name="telephone" className="js-phone-mask form-control" id="calc-phone" placeholder="Телефон" />
                    <input type="hidden" name="title-telephone" value="Телефон" />
                    <label htmlFor="calc-phone">Телефон для связи <span
                        className="text-red">*</span></label>
                </div>
                {feedback && feedback.validate_errors && feedback.validate_errors.telephone
                    ? <span className="validate-error">{feedback.validate_errors.telephone}</span>
                    : null
                }
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6">
                <div className="form-floating">
                    <input required type="email" name="email" className="form-control" id="calc-email" placeholder="Email"/>
                    <input type="hidden" name="title-email" value="Email" />
                    <label htmlFor="calc-email">Email для предложения <span
                        className="text-red">*</span></label>
                </div>
                {feedback && feedback.validate_errors && feedback.validate_errors.email
                    ? <span className="validate-error">{feedback.validate_errors.email}</span>
                    : null
                }
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6">
                <div className="form-floating">
                    <input required type="text" name="name" className="form-control" id="calc-name"placeholder="Ваше имя" />
                    <input type="hidden" name="title-name" value="Имя" />
                    <label htmlFor="calc-name">Ваше имя <span className="text-red">*</span></label>
                </div>
                {feedback && feedback.validate_errors && feedback.validate_errors.name
                    ? <span className="validate-error">{feedback.validate_errors.name}</span>
                    : null
                }
            </div>
            <div className="w-100"></div>
            <input type="hidden" name="send-to" value={data.sendTo} />
            {feedback && feedback.send
                ? <p className="send-message">Спасибо. Ваше сообщение отправлено. Мы свяжемся с вами в ближайшее время.</p>
                : <>
                    <div className="col-lg-3 mt-24">
                        <button
                            type="button"
                            className="calc-form__btn btn-block"
                            onClick={sendFeedback}
                        >Получить предложение
                        </button>
                    </div>
                    <div className="col-lg-3 mt-24">
                        <div className="text-red">Скидка 5% на первые 6 месяцев проката — бонус при онлайн заказе</div>
                    </div>
                </>
            }
        </div>
    )

}

export default FormSendBlock