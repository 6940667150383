/*
    Created on : 12.12.2021, 06:18:59
    Author     : Andy Kar
*/
import React, {useEffect, useState} from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
// import "swiper/css";
// import "swiper/css/pagination";
import "./FormSliderBlock.scss";
import SwiperCore, {Pagination} from 'swiper';
import {
    InitDataProductOptionsPriceValuesType,
    ParamsInterface,
    StoreInterface
} from "../../interfaces/mainInterfaces";
import {useSelector} from "react-redux";
import classNames from "classnames";
SwiperCore.use([Pagination]);

function FormSliderBlock() {
    const params:ParamsInterface = useSelector((state:StoreInterface) => state.params)
    
    const priceType:InitDataProductOptionsPriceValuesType = params.sticker_format.f_type || "Base"
    const [sw, setSw] = useState(false)
    const [slide, setSlide] = useState(0)
    let swiper:any|null = null


    const images:string[] = [
        "/calc-public-transport/image/marsh/" + priceType + '/Left_Big.png',
        "/calc-public-transport/image/marsh/"+ priceType + '/Front_Big.png',
        "/calc-public-transport/image/marsh/"+ priceType + '/Back_Big.png',
        "/calc-public-transport/image/marsh/"+ priceType + '/Right_Big.png',
    ]

    const renderPaginationBullet = () => {
        //@ts-ignore
        swiper = document.querySelector('.swiper').swiper;
        return images.map((image, index) => {
            return (
                <div className={classNames('pagination-bullet', {active: slide == index})}>
                    <img
                        src={image}
                        onClick={() => swiper.slideTo(index)}
                    />
                </div>
            )
        })
    }
    const renderPagination = () => {

        return (
            <div className="calc-form__slider-pagination swiper-pagination">
                {renderPaginationBullet()}
            </div>
        )
    }

    const slides = () => {
        return images.map((image, index) => {
            return (
                <SwiperSlide>
                    <img src={image} />
                </SwiperSlide>
            )
        })
    }

    return (
        <div className="calc-form__slider">
            <div>
                <Swiper
                    className="swiper js-calc-branding calc-form__slider-main"
                    spaceBetween={0}
                    slidesPerView={1}
                    onSlideChange={() => setSlide(swiper?swiper.activeIndex:1)}
                    onSwiper={() => setSw(true)}
                >
                   {slides()}
                </Swiper>
                {sw ? renderPagination(): null}
            </div>
        </div>
    )
}

export default FormSliderBlock