import {takeEvery, put} from 'redux-saga/effects'
import { FETCH_INIT_INFO, SET_INIT_INFO } from '../store/actions/actionTypes'
import API_INIT from "../utils/API_INIT";
import {ResponseInterface} from "../interfaces/mainInterfaces";

/**
 Get init main variables from server and set it to store
**/
export  function* initWatcher() {
    yield takeEvery(FETCH_INIT_INFO, initWorker)
}
export function* initWorker() {
    const data: {} = yield getInitInfo()
    if(Object.keys(data).length){ yield put({type: SET_INIT_INFO, payload: data}) }
}
async function getInitInfo() {
    const response:ResponseInterface = await API_INIT.get('data.json')
    return response.data || {}
}