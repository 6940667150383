import React, {useEffect, useState} from 'react';
import './styles/main.scss'
import {useDispatch} from "react-redux";
import {FETCH_INIT_INFO} from "./store/actions/actionTypes";
import Calculator from "./components/Calculator/Calculator";

function App() {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch({type: FETCH_INIT_INFO})
    }, [])

    return <Calculator />
}

export default App;
