/*
    Created on : 12.12.2021, 06:18:59
    Author     : Andy Kar
*/
import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {
    InitDataInterface,
    InitDataProductOptionsPriceValuesType,
    ParamsInterface,
    StoreInterface
} from "../../interfaces/mainInterfaces";
import {SET_TAX} from "../../store/actions/actionTypes";

function FormTotalBlock() {
    const data:InitDataInterface = useSelector((state:StoreInterface) => state.init.data)
    const params:ParamsInterface = useSelector((state:StoreInterface) => state.params)
    const priceType:InitDataProductOptionsPriceValuesType = params.sticker_format.f_type || "Base"
    const exchangeRate:number = data.exchangeRate ? data.exchangeRate : 1
    const monthRentalPrice:number = data.monthRentalPrice ? data.monthRentalPrice : 100

    let basePrice:number = 0
    if(data.products.prices && data.products.prices.product_option_value){
        basePrice = data.products.prices.product_option_value[priceType].price_num || 0
    }

    const company_duration:number = params.company_duration || 3
    const transport_count:number = params.transport_count
    const vatValue:number = params.tax || 1

    // const discount = {
    //     'start': 2,
    //     'max': 12,
    //     'discount': 5,
    //     'add': 1
    // }

    const total = (monthRentalPrice * company_duration  + basePrice) * transport_count
    let monthPrice = total / company_duration

    let monthDiscount = 0
    if(transport_count > 1 && transport_count <= 12){
        monthDiscount = 5 + (transport_count - 2)
    } else if(transport_count > 12){
        monthDiscount = 15
    }
    monthPrice = monthPrice * (1 - monthDiscount/100);

    const monthPay = Math.round(monthPrice * vatValue * exchangeRate * 100) / 100
    const totalSum = monthPay * company_duration
    const totalSumString:string = totalSum.toFixed( 2 );

    const dispatch = useDispatch()
    const [vat, setVat] = useState(0)
    const setParams = () => {
        if(vat == 1){
            setVat(0)
            dispatch({type: SET_TAX, payload: 1 })
        } else {
            setVat(1)
            dispatch({type: SET_TAX, payload: data.vat })
        }
    }

    return (
        <div className="calc-form__total">
            <div className="row">
                <div className="col-lg-3 col-md-4 col-sm-5">
                    <div className="h6">Стоимость компании:</div>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-7">
                    <div className="h6"><span>{totalSumString}</span> BYN</div>
                    <input type="hidden" name="total-sum" value={totalSumString} />
                    <input type="hidden" name="title-total-sum" value="Стоимость компании" />
                </div>
                {data && data.vat
                    ? <div className="col-lg-3">
                        <div className="form-check form-switch">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                name="vat"
                                role="switch"
                                id="tax"
                                value={vat ? "да" : vat}
                                onChange={() => setParams()}
                            />
                            <input type="hidden" name="title-no_vat" value="c НДС"/>
                            <label className="form-check-label" htmlFor="tax">c НДС</label>
                        </div>
                    </div>
                    : null
                }
            </div>
            <div className="row">
                <div className="col-lg-3 col-md-4 col-sm-5">
                    <div className="h6">Ежемесячный платеж:</div>
                </div>
                <div className="col-lg-7 col-md-8 col-sm-7">
                    <div className="h6"><span>{monthPay}</span> BYN</div>
                    <input type="hidden" name="month-pay" value={monthPay} />
                    <input type="hidden" name="title-month-pay" value="Ежемесячный платеж" />
                </div>
            </div>
            <div className="row">
                <div className="col-lg-3 col-md-4 col-sm-5">
                    <div className="h6">Срок запуска кампании:</div>
                </div>
                <div className="col-lg-7 col-md-8 col-sm-7">
                    <div className="h6">от <span>2</span> дней</div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-3 col-md-4 col-sm-5">
                    <div className="h6">В стоимость включены:</div>
                </div>
                <div className="col-lg-7 col-md-8 col-sm-7">
                    <div className="h6">
                        Адаптация макета, согласование, монтаж-демонтаж, фотоотчет, гарантийное обслуживание{data && data.vat && vat ? ", НДС." : "."}
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="small text-muted">Указанные на сайте цены не являются коммерческим предложением
                        и носят информационный характер.
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FormTotalBlock