/*
    Created on : 02.11.2022, 06:18:59
    Author     : Andy Kar
*/
import {
    SET_FEEDBACK_LOADING,
    SET_FEEDBACK_REQUEST_INFO
} from '../actions/actionTypes'

let initial = {
    feedback: {},
    loading: false,
}

export default function feedbackReducer(state = initial, action:{type:string, payload:any}){
    switch(action.type){
        case SET_FEEDBACK_REQUEST_INFO:
            return {
                ...state,
                feedback: action.payload,
            }
        case SET_FEEDBACK_LOADING:
            return {
                ...state,
                loading: action.payload,
            }
        default:
            return state
    }
}