import { SET_INIT_INFO } from '../actions/actionTypes'
import {InitDataInterface} from "../../interfaces/mainInterfaces";

let initial = {
    data:{
        "currency_symbol": "",
        "category_url": "",
        "title": "",
        "description": "",
        "short_description": "",
        "tabs_description": [],
        "width": "",
        "height": "",
        "empty": "",
        "products": {
            "item_id": "",
            "thumb": "",
            "images": [],
            "title": "",
            "description": "",
            "short_description": "",
            "tab_description": [],
            "href": "",
            "meta_title": "",
            "options": {},
            "prices": {}
        }
    }
}

export default function initReducer(state = initial, action:{type:string, payload:InitDataInterface}){
    switch(action.type){
        case SET_INIT_INFO:
            return {
                ...state,
               data: action.payload
            }

        default:
            return state
    }
}