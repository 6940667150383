/*
    Created on : 12.12.2022, 06:18:59
    Author     : Andy Kar
*/

export const FETCH_INIT_INFO:string = 'FETCH_INIT_INFO'
export const SET_INIT_INFO:string = 'SET_INIT_INFO'
export const SET_STICKER_FORMAT:string = 'SET_STICKER_FORMAT'
export const SET_TRANSPORT_COUNT:string = 'SET_TRANSPORT_COUNT'
export const SET_COMPANY_DURATION:string = 'SET_COMPANY_DURATION'
export const SET_TAX:string = 'SET_TAX'

export const SEND_FEEDBACK_INFO:string = 'SEND_FEEDBACK_INFO'
export const SET_FEEDBACK_REQUEST_INFO:string = 'SET_FEEDBACK_REQUEST_INFO'
export const SET_FEEDBACK_LOADING:string = 'SET_FEEDBACK_LOADING'


