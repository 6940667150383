import axios from "axios";
let Url = '';
const api_token = 'OCKOPqwe6QE0jboU8TwJpf7YmQx7Dn0AdGz01jdxjOrIJPYV6cie8wPsBNwKkZfbhkkXJPtJl5rQdhPJ8OsirBaIi1yrFTFd3kWP3XA5xxB5rLparmvLzcoilrkDe4QDbV2lWTBuPL83GHQziNR6j32yP1Y7em1hm0mVovOnHIbfxAtcSS1MQMuAuKDUXYpLmbCYxIROigPhGMJnqnTYqboqqGMUNP5xWlW1Wb4wWIBXQEIEqhzjvaG0VT1zWmlW'

const API_MAIL = axios.create({
    baseURL: Url,
    params: {},
    //withCredentials: true,
    responseType: "json",

})

API_MAIL.interceptors.request.use((config) => {
    config.headers = config.headers || {}
    config.headers = {
        'Api-Token': api_token,

    }
    config.params = config.params || {};
    //config.params['api_token'] = api_token;
    return config;
})

export default API_MAIL