/*
    Created on : 12.12.2022, 06:18:59
    Author     : Andy Kar
*/

import {all} from 'redux-saga/effects'
import {initWatcher} from "./initSaga";
import {feedbackWatcher} from "./feedbackSaga";

export function* rootWatcher() {
    yield all([
        initWatcher(),
        feedbackWatcher(),
    ])
}