import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import {Provider} from 'react-redux';
import createSagaMiddleware from 'redux-saga'
import {createStore, compose, applyMiddleware} from 'redux';
import rootReducer from './store/reducers/rootReducer'

import reportWebVitals from './reportWebVitals';
import {rootWatcher} from "./saga/rootSaga";
//import 'bootstrap/dist/css/bootstrap.min.css';

declare global {
    interface Window {
        __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
    }
}
const saga = createSagaMiddleware()
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(rootReducer,
    composeEnhancers(
        compose(
            applyMiddleware(saga)
        )
    )
)

saga.run(rootWatcher)

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store} >
            <App />
        </Provider>
    </React.StrictMode>,
  document.getElementById('calc-public-transport')
);
reportWebVitals();
