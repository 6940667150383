import {
    SET_STICKER_FORMAT,
    SET_TRANSPORT_COUNT, SET_COMPANY_DURATION, SET_TAX,
} from '../actions/actionTypes'

let initial = {
    sticker_format: {},
    transport_count: 1,
    company_duration: 3,
    tax: 1
}

export default function calculateParams(state = initial, action:{type:string, payload:any}){
    switch(action.type){
        case SET_STICKER_FORMAT:
            return {
                ...state,
                sticker_format: action.payload
            }
        case SET_TRANSPORT_COUNT:
            return {
                ...state,
                transport_count: action.payload
            }
        case SET_COMPANY_DURATION:
            return {
                ...state,
                company_duration: action.payload
            }
        case SET_TAX:
            return {
                ...state,
                tax: action.payload
            }

        default:
            return state
    }
}