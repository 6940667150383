/*
    Created on : 12.12.2021, 06:18:59
    Author     : Andy Kar
*/
import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {
    InitDataInterface,
    InitDataProductOptionsInterface,
    InitDataProductOptionsNamesType,
    StoreInterface
} from "../../interfaces/mainInterfaces";
import {
    SET_COMPANY_DURATION,
    SET_STICKER_FORMAT, SET_TAX,
    SET_TRANSPORT_COUNT
} from "../../store/actions/actionTypes";

function FormCountBlock(props:{block_name:InitDataProductOptionsNamesType}) {
    const data:InitDataInterface = useSelector((state:StoreInterface) => state.init.data)
    const options:InitDataProductOptionsInterface = data.products.options
    const block:InitDataProductOptionsInterface|undefined = options[props.block_name]
    const [message, setMessage] = useState("")
    const [countValue, setCountValue] = useState(1)
    const dispatch = useDispatch()

    useEffect(() => {
        if(block && block.message){
            setParams(block && block.value ? parseInt(block.value):1)
            setMessage(block.message)
            setCountValue(parseInt(block && block.value ? block.value:"1"))
        }
    },[data])

    const actions = {
        sticker_format: SET_STICKER_FORMAT,
        transport_count: SET_TRANSPORT_COUNT,
        company_duration: SET_COMPANY_DURATION,
        tax: SET_TAX,
    }

    const setParams = (value:number) => {
        setCountValue(value)
        dispatch({type: actions[props.block_name], payload: value})
    }

    if(block) {
        return (
            <div className="calc-form__input-block">
                <div className="title">{block.name}</div>
                <div className="input-group input-calc">
                <span
                    className="btn-outline input-calc__minus"
                    onClick={() => countValue > parseInt(block.value ? block.value:"1") ? setParams(countValue - 1) : null}
                >–</span>
                    <input
                        type="number"
                        className="form-control input-calc__count"
                        name={props.block_name}
                        defaultValue={parseInt(block.value ? block.value:"1")}
                        min={block.value}
                        value={countValue}
                    />
                    <input type="hidden" name={"title-" + props.block_name} value={block.name} />
                    <span
                        className="btn-outline input-calc__plus"
                        onClick={() => setParams(countValue + 1)}
                    >+</span>
                </div>
                {message
                    ? <div className="small text-muted">{message}</div>
                    : null
                }
            </div>
        )
    } else {
        return null
    }
}

export default FormCountBlock