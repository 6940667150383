import {takeEvery, put, call} from 'redux-saga/effects'
import {
    SEND_FEEDBACK_INFO,
    SET_FEEDBACK_LOADING,
    SET_FEEDBACK_REQUEST_INFO,
} from '../store/actions/actionTypes'
import API_MAIL from "../utils/API_MAIL";


/**
 Send feedback data to server
 **/
export  function* feedbackWatcher() {
    yield takeEvery(SEND_FEEDBACK_INFO, feedbackWorker)
}
export function* feedbackWorker(action:{type:string, feedbackData: any}) {
    yield put({type: SET_FEEDBACK_LOADING, payload: true})
    const data: {} = yield call(sendFeedbackInfo, action.feedbackData)
    yield put({type: SET_FEEDBACK_LOADING, payload: false})
    if(Object.keys(data).length){ yield put({type: SET_FEEDBACK_REQUEST_INFO, payload: data}) }
}
async function sendFeedbackInfo(feedbackData: any) {
    const response = await API_MAIL.post(feedbackData.url, feedbackData.postData)
    return response.data || {}
}
