/*
    Created on : 12.12.2021, 06:18:59
    Author     : Andy Kar
*/
import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {
    InitDataInterface, InitDataProductOptionsInterface, InitDataProductOptionsNamesType,
    InitDataProductOptionValuesInterface,
    StoreInterface
} from "../../interfaces/mainInterfaces";
import {
    SET_COMPANY_DURATION,
    SET_STICKER_FORMAT, SET_TAX,
    SET_TRANSPORT_COUNT
} from "../../store/actions/actionTypes";

function FormButtonBlock(props:{block_name:InitDataProductOptionsNamesType}) {
    const data:InitDataInterface = useSelector((state:StoreInterface) => state.init.data)
    const options:InitDataProductOptionsInterface = data.products.options
    const block:InitDataProductOptionsInterface|undefined = options[props.block_name]
    const [message, setMessage] = useState("")
    const dispatch = useDispatch()

    useEffect(() => {
        if(block && block.product_option_value[0]){
            setParams(block.product_option_value[0])
        }
    },[data])

    const actions = {
        sticker_format: SET_STICKER_FORMAT,
        transport_count: SET_TRANSPORT_COUNT,
        company_duration: SET_COMPANY_DURATION,
        tax: SET_TAX,
    }

    const setParams = (value:InitDataProductOptionValuesInterface) => {
        if(value.info) { setMessage(value.info) }
        dispatch({type: actions[props.block_name], payload: value})
    }

    const renderButtonGroup = (group:[InitDataProductOptionValuesInterface]) => {
        if(group) {
            return group.map((value, index) => {
                return(
                    <>
                        {index === 0
                            ? <input
                                type="radio"
                                className="btn-check"
                                name={props.block_name}
                                id={props.block_name + "_" + (index + 1)}
                                autoComplete="off"
                                defaultChecked
                                onChange={() => setParams(value)}
                                value={value.name}
                            />
                            : <input
                                type="radio"
                                className="btn-check"
                                name={props.block_name}
                                id={props.block_name + "_" + (index + 1)}
                                autoComplete="off"
                                onChange={() => setParams(value)}
                                value={value.name}
                            />
                        }
                        <label className="btn" htmlFor={props.block_name + "_" + (index + 1)}>{value.name}</label>
                    </>
                )
            })
        } else {
            return null
        }
    }

    if(block) {
        return (
            <div className="calc-form__input-block">
                <div className="title">{block.name}</div>
                <div className="btn-group" role="group">
                    {renderButtonGroup(block.product_option_value)}
                    <input type="hidden" name={"title-" + props.block_name} value={block.name} />
                </div>
                {message
                    ? <div className="message">{message}</div>
                    : null
                }
            </div>
        )
    } else {
        return  null
    }

}

export default FormButtonBlock