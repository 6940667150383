/*
    Created on : 12.12.2021, 06:18:59
    Author     : Andy Kar
*/
import React from 'react'
import {useSelector} from "react-redux";
import {
    InitDataInterface,
    StoreInterface
} from "../../interfaces/mainInterfaces";
import FormButtonBlock from "../FormBlock/FormButtonBlock";
import FormCountBlock from "../FormBlock/FormCountBlock";
import FormTotalBlock from "../FormBlock/FormTotalBlock";
import FormSendBlock from "../FormBlock/FormSendBlock";
import FormSliderBlock from "../FormBlock/FormSliderBlock";

function Calculator() {
    const data:InitDataInterface = useSelector((state:StoreInterface) => state.init.data)

    return(
        <div className="calc-corp-transport-wrap">
            <div className="section calc-form">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <h2 className="calc-form__title h3">{data.title}</h2>
                        </div>
                    </div>
                    <form name="publicCalcForm">
                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-6">
                                <FormButtonBlock block_name='sticker_format' />
                                <FormCountBlock block_name='transport_count'/>
                                <FormCountBlock block_name='company_duration'/>
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-6">
                                <FormSliderBlock/>
                            </div>
                            <div className="row mt-56">
                                <div className="col-lg-12">
                                    <FormTotalBlock/>
                                </div>
                            </div>
                        </div>
                        <FormSendBlock/>
                    </form>
                </div>
            </div>
        </div>
    )

}
export default Calculator